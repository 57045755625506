
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src\
import PopUp from "@/components/PopUp.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import utils from "@/utils/utils";

@Component({
  components: { Header, Loader, PopUp, FilterComponent },
})
export default class UserManagement extends Vue {
  public currentPage = 1;
  loading = true;
  public selected_groups: any = [];
  public selected_user: any = [];
  public permitUser: any = [];
  public search_user: any = [];
  openPermission = false;
  openGroup = false;
  module = [];
  screen = [];
  permission = [];
  group = [];
  public userObject: any = {};
  saveData = true;
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  formatPermissions(permissionArray: any) {
    return permissionArray.join(', ');
  }
  togglePermissionDropdown() {
    this.openPermission = !this.openPermission;
    this.getPermission();
  }
  toggleGroupDropdown() {
    this.openGroup = !this.openGroup;
    this.get_groups();
  }
  public permission_options: any = [];
  getPermission() {
    this.loading = true;
    UserManagementService.get_screen_permission()
      .then((res) => {
        this.permission_options = res.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get_groups() {
    this.loading = true;
    UserManagementService.get_groups()
      .then((res) => {
        this.selected_groups = res.data.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  getAllUsers() {
    this.loading = true;
    UserManagementService.getAll_user()
      .then((res) => {
        this.permitUser = res.data.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  retrieve() {
    this.loading = true;
    UserManagementService.get_user()
      .then((res) => {
        this.selected_user = res.data.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get rows() {
    return this.selected_user.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.selected_user.slice(start, end);
  }
  mounted() {
    this.get_groups();
    this.retrieve();
    this.getPermission();
    this.getAllUsers();
  }
  saveUserPermission() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.userObject, ['employee_title', 'title'])
    if (error_list.length > 0 || (this.permission === null || this.permission.length === 0 || this.permission === undefined) && (this.group === null || this.group.length === 0 || this.group === undefined)) {
      this.error = utils.generateErrorMessage(error_list.concat((this.permission === null || this.permission.length === 0 || this.permission === undefined) && (this.group === null || this.group.length === 0 || this.group === undefined) ? 'permission or group' : []));
      // if (error_list.length > 0) {
      //   this.error = utils.generateErrorMessage(error_list)
    }
    else {
      const permission_id: any = this.permission
      const selectedPermissionId = permission_id.map((permission: any) => {
        const option = this.permission_options.find((option: any) => option.title === permission)
        return option?.id;
      })
      const group_id: any = this.group
      const selectedGroupId = group_id.map((group: any) => {
        const option = this.selected_groups.find((option: any) => option.title === group)
        return option?.id;
      })
      const selected_user_title: any = this.userObject.employee_title;
      const user_id = this.permitUser
        .filter((user: any) => user.employee_title === selected_user_title)
        .map((user: any) => user.id)[0];
      const userObject = {
        user: user_id,
        title: this.userObject.title,
        permissions: selectedPermissionId,
        group: selectedGroupId,
      };
      this.loading = true;
      UserManagementService.post_user_permission(userObject)
        .then((res) => {
          this.resetUserPermission();
          this.retrieve();
          this.refreshFilters();
          this.showModal("Created Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  editUserToggle(data: any) {
    this.saveData = false;
    this.permitUser = [];
    this.userObject = data;
    this.permitUser.push(this.userObject.id);
    this.permission = this.userObject.permission_title.slice();
    this.group = this.userObject.group_title.slice();
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-user");
  }
  editUserPermission() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.userObject, ['employee_title', 'title'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      const permission_id: any = this.permission
      const selectedPermissionId = permission_id.map((permission: any) => {
        const option = this.permission_options.find((option: any) => option.title === permission)
        return option?.id;
      })
      const group_id: any = this.group
      const selectedGroupId = group_id.map((group: any) => {
        const option = this.selected_groups.find((option: any) => option.title === group)
        return option?.id;
      })
      this.userObject.permissions = selectedPermissionId;
      this.userObject.group = selectedGroupId;
      this.loading = true;
      UserManagementService.update_user_permission(this.userObject.id, this.userObject)
        .then((res) => {
          this.resetUserPermission();
          this.retrieve();
          this.refreshFilters();
          this.showModal("Updated Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  deleteUserPermission(item: any) {
    this.loading = true;
    UserManagementService.delete_user_permission(item.id)
      .then((res) => {
        this.retrieve();
        this.refreshFilters();
        this.showModal("Deleted Successfully", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  resetUserPermission() {
    this.error = "";
    this.saveData = true;
    this.userObject = {
      id: '',
      title: ''
    }
    this.permission = [];
    this.group = [];
    for (let field of this.filter_fields) {
      field.value = "";
    }
  }
  index = 0;
  search_user_by_title(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      this.loading = true;
      UserManagementService.get_User_by_Title(title)
        .then((res) => {
          this.search_user = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    }
    if (title.length == 0) {
      this.search_user = [];
    }
  }
  get_user_data(data: any) {
    this.userObject.employee_title = data.employee_title;
    this.search_user = [];
  }
  filter_fields = [
    // { type: "text", label: "Employee Title", name: "employee-title", value: "", param_name: "", filter_type: "simple", },
    { type: "text", label: "User Permission Title", name: "user-permission-title", value: "", param_name: "title", filter_type: "simple", },
    { type: "text", label: "Permission", name: "permission", value: "", param_name: "permission_title", filter_type: "simple", },
    { type: "text", label: "Group", name: "group", value: "", param_name: "group_title", filter_type: "simple", },

  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.get_user_permissions(simpleFilteredObj).then((data) => {
        if (
          data.data.data !== undefined &&
          data.data.data !== null
        ) {
          this.selected_user = data.data.data;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  refreshFilters() {
    this.resetUserPermission();
  }
}
