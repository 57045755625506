import { render, staticRenderFns } from "./user-permissions.vue?vue&type=template&id=64cd5b56&scoped=true"
import script from "./user-permissions.vue?vue&type=script&lang=ts"
export * from "./user-permissions.vue?vue&type=script&lang=ts"
import style0 from "./user-permissions.vue?vue&type=style&index=0&id=64cd5b56&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64cd5b56",
  null
  
)

export default component.exports